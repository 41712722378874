

/**
 * 表格数据导出excel
 */
import FileSaver from "file-saver";
import XLSX from "xlsx";


/** 通过table id 获取workbook 对象
 * @param {Object} id table id
 */
export function getWorkBookObjById(id){
  // var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
  // 解决生成重复数据-因为使用fixed属性
  var fix = document.querySelector("#"+id).querySelector('.el-table__fixed')
  var wb;
  if (fix) {
    /* 从表生成工作簿对象 */
    wb = XLSX.utils.table_to_book(document.querySelector("#"+id).removeChild(fix))
    document.querySelector("#"+id).appendChild(fix)
  } else {
    wb = XLSX.utils.table_to_book(document.querySelector("#"+id))
  }
  return wb;
}
/**
 * @param {Object} id 表格 document id
 * @param {Object} fileName 导出文件名
 */
export function exportExcelByTableId(id,fileName){
  var wb=getWorkBookObjById(id);
  createExcel(wb,fileName);
}
/** 创建excel
 * @param {Object} wb work_book
 * @param {Object} fileName 导出文件名
 */
export function createExcel(wb,fileName){
  var wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array"
  });
  try {
    FileSaver.saveAs(
      new Blob([wbout], { type: "application/octet-stream;charset=utf-8" }),
      fileName+".xlsx"
    );
  } catch (e) {
    if (typeof console !== "undefined") console.log(e, wbout);
  }
  return wbout;
}

export function formatJson(filterVal, jsonData){
  // jsonData 数据转成行数据（不包含标题）
  return jsonData.map(v => filterVal.map(j => v[j]))

}

/** 通过多个表格id导出excle
 * @param {arr} paramArr [{id:"el-table id值","sheetName":"sheetName"}]
 *  * @param {arr} fileName 导出文件名 ，如果不填的话默认当前时间戳
 */
export function exportExcelByTableIds(paramArr,fileName){
  let wb =getExportWorkBookByTableIds(paramArr);
  fileName=fileName?fileName:"data-export-excel_"+new Date().toLocaleString();
  createExcel(wb,fileName);
}

/** 通过document id 参数数据获取word_book 对象
 * @param {Object} paramArr [{id:"el-table id值","sheetName":"sheetName"}]
 */
export function getExportWorkBookByTableIds(paramArr){
  let wb = XLSX.utils.book_new();
  // var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
  for(let i=0;i<paramArr.length;i++){
    var wb1 = getWorkBookObjById("#"+paramArr[i].id);//XLSX.utils.table_to_book(document.querySelector("#"+paramArr[i].id),xlsxParam);
    for(let j=0;j<wb1.SheetNames.length;j++){
      let sheetJSON=XLSX.utils.sheet_to_json(wb1.Sheets[wb1.SheetNames[j]]);
      console.log(sheetJSON,"sheetJSON");
      let sheet = XLSX.utils.json_to_sheet(sheetJSON);
      // 命名sheet名称：如果只有一个sheet就用传入进行来的sheetName,多个的话新的 sheetName=传入的sheetName + 原来的sheetName
      let sheetName=wb1.SheetNames.length>1?paramArr[i].sheetName+wb1.SheetNames[j]:paramArr[i].sheetName;
      XLSX.utils.book_append_sheet(wb, sheet, sheetName);
    }
  }
  return wb;
}

/**
 * @param {Object} headerArr 列名数组（最终导出excel的列表）如：["第一列","第二列"]
 * @param {Object} filterVal 列名数组(对应数据中的字段名) 如：["col1","col2"]
 * @param {Object} dataList 数据列表 如[{col1:"1",col2:"2"},{col1:"1",col2:"2"}]
 */
export function getSheetByJSON(headerArr,filterVal,dataList){
  let data=formatJson(filterVal,dataList);
  data.unshift(headerArr);// 在data数据第一行加上标题行
  let sheet = XLSX.utils.aoa_to_sheet(data);
  return sheet;
}
/** 通过table id 获取sheet(一个)
 * @param {Object} tableId table id
 */
export function getSheetByTableId(tableId){
  var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
  var wb = XLSX.utils.table_to_book(document.querySelector("#"+tableId),xlsxParam);

  let sheetJSON=XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
  let sheet = XLSX.utils.json_to_sheet(sheetJSON);
  return sheet;
}

/**
 * @param {Object} headerArr 列名数组（最终导出excel的列表）如：["第一列","第二列"]
 * @param {Object} filterVal 列名数组(对应数据中的字段名) 如：["col1","col2"]
 * @param {Object} dataList 数据列表 如[{col1:"1",col2:"2"},{col1:"1",col2:"2"}]
 *
 * @param {Object} fileName 导出文件名/sheetName
 */
export function exportExcelByJSON(headerArr,filterVal,dataList,fileName){
  let sheet=getSheetByJSON(headerArr,filterVal,dataList);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet, fileName);
  createExcel(wb,fileName);
}
